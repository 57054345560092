import { render, staticRenderFns } from "./DoctorStat.vue?vue&type=template&id=1c94d208&scoped=true"
import script from "./DoctorStat.vue?vue&type=script&lang=js"
export * from "./DoctorStat.vue?vue&type=script&lang=js"
import style0 from "./DoctorStat.vue?vue&type=style&index=0&id=1c94d208&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c94d208",
  null
  
)

export default component.exports